<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h2 class="my-2 font-semibold text-blue-400 text-2xl page-title">
        VFD Fund Transfer Portal
      </h2>
      <div class="my-10 flex justify-between items-center">
        <input type="text" class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
          name="" placeholder="Search" id="" v-model="search" />
        <button @click="
          $router.push({
            name: 'VFDPortalNewVFD',
          })
          "
          class="block uppercase shadow bg-blue-600 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded">
          Create VFD Account
        </button>
      </div>

      <div class="overflow-scroll pb-6">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <tr>
              <th class="text-l p-3 text-left" colspan="13" style="background: #dee6f2">
                Loan Applicant Information
              </th>
            </tr>
            <tr>
              <th class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2">
                Loan ID
              </th>
              <th class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2">
                Business Name

              </th>
              <th class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2">
                Business ID
              </th>
              <th class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2">
                Loan amount
              </th>

              <th class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2">
                VFD Account No
              </th>
              <th class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2">
                Action
              </th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(loan, i) in accounts" :key="i" class="hover:bg-gray-300">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ loan.loan_status_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ loan.business_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ loan.business_id }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ loan.loan_amount }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ loan.repayment_fixed_account_number || "N/A" }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <button :disabled="loan.loan_amount_outstanding === 0" :class="loan.loan_amount_outstanding === 0
                  ? 'bg-gray-400'
                  : 'bg-blue-800'
                  " @click="        
    $router.push({
      name: 'SweepFunds',
      params: { amount: loan.loan_amount_outstanding },
    })" class="block uppercase shadow focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded">
                  Sweep Funds
                </button>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
      <Modal size="max-w-xl" v-if="openModal" @close="openModal = false">
        <div>
          <div class="flex items-center justify-center mt-12">
            <svg width="100" height="100" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="71" cy="71" r="71" fill="#FFF8DD" />
              <path
                d="M78.2618 33.3485L76.8315 85.9384H63.409L61.9421 33.3485H78.2618ZM70.1203 109.409C67.6998 109.409 65.6217 108.554 63.8858 106.842C62.1499 105.106 61.2942 103.028 61.3186 100.608C61.2942 98.2118 62.1499 96.1581 63.8858 94.4467C65.6217 92.7352 67.6998 91.8795 70.1203 91.8795C72.4429 91.8795 74.4844 92.7352 76.2448 94.4467C78.0051 96.1581 78.8975 98.2118 78.9219 100.608C78.8975 102.221 78.4696 103.701 77.6384 105.045C76.8315 106.366 75.768 107.429 74.4478 108.236C73.1275 109.018 71.685 109.409 70.1203 109.409Z"
                fill="#E8B637" />
            </svg>
          </div>
          <div class="text-center text-xl font-medium px-32 mt-5">
            Are you sure you want to push this loan to disbursal?
          </div>
          <div class="px-32 flex justify-between items-center mt-10 mb-8">
            <button @click="disburse" :disabled="buttonLoad" class="bg-blue-600 py-3 w-32 px-6 rounded-md text-white">
              Yes
            </button>
            <button @click="openModal = false" class="bg-red-600 py-3 w-32 px-6 rounded-md text-white">
              No
            </button>
          </div>
        </div>
      </Modal>

      <Modal size="max-w-xl" v-if="successModal" @close="successModal = false">
        <div>
          <div class="flex items-center justify-center mt-12">
            <svg width="100" height="100" viewBox="0 0 143 142" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_38_14)">
                <circle cx="71.7041" cy="71" r="71" fill="#EBFFED" />
                <path d="M100.104 49.7001L61.0542 88.7501L43.3042 71.0001" stroke="#10C64E" stroke-width="7.1"
                  stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_38_14">
                  <rect width="142" height="142" fill="white" transform="translate(0.704102)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="text-center text-xl font-medium px-32 mt-5">
            Successful
          </div>
          <p class="text-center mt-1 mb-6">
            Your loan has been pushed to disbursal
          </p>
        </div>
      </Modal>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Loading from "./general-components/Loading.vue";
import Modal from "../components/Modal.vue";

export default {
  name: "Loans",
  data() {
    return {
      isLoading: false,
      search: "",
      moment,
      accounts: [],
      openModal: false,
      currentLoan: {},
      buttonLoad: false,
      successModal: false,
    };
  },
  components: {
    Loading,
    Modal,
  },
  computed: {
    ...mapGetters(["GET_LOANS"]),
    filterLoans() {
      return this.GET_LOANS.data.item;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    async fetchVfd() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("GET_ALL_VFD");
        this.accounts = res.data.item;
        this.isLoading = false;
      } catch (error) {
        console.log(error, "error");
      }

      this.isLoading = false;
    },
    async disburse() {
      this.buttonLoad = true;
      try {
        let res = await this.$store.dispatch("DISBURSE_VFD", {
          AccountNo: this.currentLoan.repayment_fixed_account_number,
          LoanAmount: this.currentLoan.loan_amount,
          Duration: this.currentLoan.tenor,
        });
        if (res) {
          this.successModal = true;
          this.openModal = false;
        }
        this.buttonLoad = false;
        this.fetchVfd();
      } catch (error) {
        console.log(error, "error");
      }

      this.buttonLoad = false;
    },
  },
  async mounted() {
    await this.fetchVfd();
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}

table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}

table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}

table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}

table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}

table tr td {
  background: #f0f6fd;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
